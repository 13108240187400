/**
 * Global Constants
 */
 export const globalConstants = Object.freeze({
	login41: 'https://col.connectionsonline.net/zendesk/sso?return_to=',
    login50: 'https://app.connectionsonline.net/sign-in?return_to=',
    // login50: 'http://localhost:8100/sign-in?ReturnUrl=',
    logout41: 'https://col.connectionsonline.net/logout.aspx?return_to=',
    // logout50: 'http://localhost:8100/sign-out?ReturnUrl=',
    logout50: 'http://app.connectionsonline.net/sign-out?return_to=',
    storageVersion: 'AUTH.COL.VERSION'
});
