import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { globalConstants } from '../app.globals';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  retUrl = '';
  loading = true;

  constructor(route: ActivatedRoute) {
    // get the redirect Url and version from query params
    route.queryParams.subscribe(params => {
      //TODO: This should error if there is no Return URL
      window.alert('Params: ' + params.length);
      this.retUrl = params.ReturnUrl;
    });
  }

  ngOnInit() {
    //default to 4.1
    let url = globalConstants.logout41;

    //check local storage for version
    const v = localStorage.getItem(globalConstants.storageVersion);
    if (v && v === '5.0') {
        url = globalConstants.logout50;
    }

    this.loading = false;
    window.location.href = url + this.retUrl;
  }

}
